<template>
    <apexchart ref="chart" type="line" :options="options" :series="series" :width="width" :height="height"></apexchart>
</template>

<script>
import API_HISOTRY_GRAPH from '../API/reading/historyGraph'
export default {
    components: {
    },
    props: {
        xrayType: {
            default : ""
        },
        xrayNo: {
            default : 0
        },
        width: {
            default : '100%'
        },
        height: {
            default: '100%'
        },
        isScoliosis: {
            default: false
        },
        isReport: {

            default: true
        }
    },
    data() {
        return {
            loading: false,
            updateKey: 0,
            data1List: [],
            data2List: [],
            timeList: [],
            dataList: [],
            options: {
                chart: {
                    animations: {
                        easing: 'easeinout',
                        speed: 500,
                    },
                    foreColor: '#000',
                    id: 'vuechart-example',
                    type: 'line',
                    background: {opacity: 0},
                    zoom: {enabled: false},
                    toolbar: {tools: {download: false}},
                },
                theme: {mode: 'dark'},
                xaxis: {
                    tooltip: {enabled: false},
                    labels: {style: {fontSize: '9.5px'}},
                    categories: this.timeList,
                },
                colors: ['#eb4d4b', '#30336b'],
            },
            series: []
        };
    },
    watch: {
        '$i18n.locale': function() {
            if(this.xrayType === 'spine') this.updateSpineSeries();
            if(this.xrayType === 'hands') {
                this.updateHandsData();    
                this.updateHandsSeries();
            }
        }
    },
    created() { },
    async mounted() {
        await this.getHistoryGraph();
    },
    destroyed() { },
    methods: {
        async getHistoryGraph() {
            if (this.loading) return;
            this.loading = true;
            let res = await API_HISOTRY_GRAPH.request(this.xrayType, this.xrayNo);
            if (res.isSuccess) {
                this.dataList = res.list ?? [];
                this.initDataList(this.dataList);
                switch (this.xrayType) {
                    case 'spine':
                        this.setSpineGraph(this.dataList);
                        break;
                    case 'hands':
                        this.setHandsGraph(this.dataList);
                        break;
                }
                this.loading = false;
            } else {
                this.showPopup(res.errorMsg, res.status);
                this.loading = false;
            }
            this.$emit('loading', this.loading)
        },
        initDataList(dataList) {
            if (!Array.isArray(dataList)) {
                console.error('dataList is not an array');
                return;
            }
            this.data1List = [];
            this.data2List = [];
            this.timeList = [];
        },
        setSpineGraph(dataList) {
            dataList.forEach(element => {
                if (element && typeof element.data1 === 'number' && element.pictureDt) {
                    this.data1List.push(element.data1);
                    const date = new Date(element.pictureDt);
                    this.timeList.push(this.setDate(date));
                } else {
                    console.error('Invalid data format in dataList:', element);
                }
            });
            this.updateSpineSeries();
            this.setOptions('spine', this.isReport);
        },
        setHandsGraph(dataList) {
            this.initDataList(dataList);
            this.updateHandsData();
            this.updateHandsSeries();
            this.setOptions('hands', this.isReport);
        },
        updateHandsData() {
            this.initDataList(this.dataList);
            this.dataList.forEach(element => {
                if (element && typeof element.data1 === 'number' && element.pictureDt) {
                    this.data1List.push(element.data1);
                    const date = new Date(element.pictureDt);
                    let formattedList = []
                    formattedList.push(this.setDate(date))
                    let ageStr = this.translateAge(element.dataStr);
                    formattedList.push(`(${ageStr})`)
                    this.timeList.push(formattedList);
                } else {
                    console.error('Invalid data format in dataList:', element);
                }
            });
            this.updateOption('hands')
        },
        setDate(date) {
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        updateOption(type){
            this.setOptions(type)
        },
        setOptions(type) {
            let xaxisOption = {
                xaxis: {categories: this.timeList},
            }
            switch (type) {
                case 'spine':
                    this.options = {
                        ...this.options,
                        ...xaxisOption,
                        ...{    
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        return val.toFixed(1) + " °"
                                    },
                                },
                                forceNiceScale: true
                            },
                            legend: {showForSingleSeries: true}
                        }
                    }
                    break;
                case 'hands':
                    const yearText = this.yearText;
                    const monthText = this.monthText;
                    this.options = {
                        ...this.options,
                        ...xaxisOption,
                        ...{
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        let result = "";
                                        const years = Math.floor(val);
                                        const months = Math.round((val - years) / 8.333 * 100);
                                        result = years + yearText;
                                        if (months > 0) {
                                            result += " " + months + monthText;
                                        }
                                        return result;
                                    },
                                    minWidth: 45,
                                    forceNiceScale: true,
                                },
                            }
                        }
                    }
                    break;
                }
        },
        updateSpineSeries() {
            this.series = [
                {
                    name: this.maxAngleText,
                    data: this.data1List,
                },
            ];
            this.updateKey += 1;
        },
        updateHandsSeries(){
            this.series = [
                {
                    name: this.boneAgeText,
                    data: this.data1List,
                },
            ];
            this.updateKey += 1;
        }
    },
    computed: {
        maxAngleText(){
            return this.$t('common.analysis.result.spine.max')
        },
        boneAgeText(){
            return this.$t('common.analysis.type.hands')
        },
        yearText() {
        return this.$t('common.age.year'); 
        },
        monthText() {
            return this.$t('common.age.month');
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
</style>